.terms_wrapper{
  display: flex;
  flex-direction: column;
  padding: 15px 45px;
}

ol li{
  margin-left: 15px;
  font-size: 16px;
}

.listHeading{
  font-weight: 600;
}


@media only screen and (max-width: 768px) {
  .terms_wrapper{
    display: flex;
    flex-direction: column;
    padding: 15px 20px;
  }

  p{
    font-size: 16px;
  }

}


