.footer-wrapper{
  display: flex;
  flex-flow: column;
  justify-content: center;
  gap: 20px;
  padding: 40px 30px 20px;
  background-color: #252525;
}

.footer-wrapper-mobile{
    display: none;
    visibility: hidden;
}

.footer-container-one {
  display: flex;
  justify-content: space-around;
}

.footer-box {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 25%;
}

/* .one {
  gap: 6px;
} */

.footer-section-title {
  color: rgb(169, 169, 169);
  font-size: 14px;
  font-weight: 600;
}

.footer-links {
  color: #fff;
  font-size: 14px;
  text-decoration: none;
}

.footer-links:hover {
  text-decoration: underline;
}

.contact-box {
  display: flex;
  gap: 10px;
  align-items: center;
}

.contact-box img {
  width: 20px;
}

.contact-box a {
  color: #fff;
  cursor: pointer;
}

.contact-box p {
  color: #fff;
}

#badge {
  width: 180px;
  position: relative;
  right: 12px;
  bottom: 5px;
}

#hutti_logo_footer {
  width: 110px;
  position: relative;
  right: 6px;
  bottom: 2px;
}

#follow-us {
  font-size: 14px;
  font-weight: 600;
  color: #fff;
}

.social-media {
  display: flex;
  gap: 10px;
}

.social-media img {
  width: 30px;
  height: 30px;
}

.footer-container-two ul p {
text-align: center;
}

#copyright{
  color: #fff;
  font-size: 14px;
}

#companyname{
  color: red;
}

@media only screen and (max-width : 768px){
  .footer-wrapper{
    display: none;
    visibility: hidden;
  }

  .footer-wrapper-mobile{
    visibility: visible;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 30px;
    padding: 40px 10px 10px;
    background-color: #252525;
  }

  .footer-container-one {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap: 30px;
  }

  .footer-container-one div:nth-child(1){
    display: flex;
    gap: 10px;
    padding: 0 10px;
  }

  .footer-container-one div:nth-child(2){
    display: flex;
    /* padding: 0 10px; */
    
  }

  .footer-box {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
  }

  .contact-box{
    display: flex;
    align-items: flex-start;
    position: relative;
  }

 

}

.video-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.video-popup-content {
  background-color: #fff;
  padding: 10px 20px;
  border-radius: 8px;
  max-width: 800px;
  width: 100%;
}

.close-popup {
  cursor: pointer;
  float: right;
  background: transparent;
}

.video-popup-content img{
  width: 25px;
  background: transparent;
}

.address:hover{
  text-decoration: none;
}

.f-subheading:hover{
  text-decoration: none;
}
