.stores-wrapper {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding:40px 10px;
}


.stores-wrapper-mobile{
  display: none;
  visibility: hidden;
}

.stores-img{
  text-align: center;
  width: 50%;
  padding: 30px 10px;
}

.stores-img img{
  max-width: 70%;
  /* height: 300px; */
}

.stores-heading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 40%;
}

.stores-heading p:nth-child(1) {
  font-size: 28px;
  font-weight: 500;
  line-height: 36px;
}

.stores-heading p:nth-child(2) {
  font-size: 20px;
  font-weight: 500;
  color: gray;
}

@media only screen and (max-width : 768px){
  .stores-wrapper{
    display: none;
    visibility: hidden;
  }


  .stores-wrapper-mobile{
    visibility: visible;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 60px 10px 30px;
    gap: 20px;
  }

  .stores-heading{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 85%;
  }

  .stores-heading p:nth-child(1) {
    font-size: 22px;
    font-weight: 500;
    line-height: 36px;
  }
  
  /* .stores-heading p:nth-child(2) {
    font-size: 17px;
    font-weight: 500;
    color: gray;
  } */

  .stores-heading.mob{
    width: 80%;
  }

  .stores-img{
    text-align: center;
    width: 90%;
    padding: 30px 10px;
  }
  
  .stores-img img{
    max-width: 100%;
    /* height: 300px; */
  }
  
}


