.steps-wrapper{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 60px 10px 40px;
  gap: 30px;
  /* background-color: aliceblue; */
}

.steps-wrapper-mobile{
  display: none;
  visibility: hidden;
}

.steps-page-heading p {
  font-size: 32px;
  font-weight: 500;
}

.steps-info{
  display: flex;
  width: 100%;
  gap: 60px;
  justify-content: space-around;
  align-items: center;

}

.steps-info div{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  width: 100%;
  text-align: center;
}

.steps-info div div:nth-child(1){
  font-size: 22px;
  font-weight: 600;
  color: #fb3310;
}

.steps-info div div:nth-child(2){
  font-size: 17px;
  font-weight: 500;
}

.img-box{
  width: 200px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.img-box img{
  width: 180px;
}

/* .steps-info div img{
  width: 180px;
  position: relative;
  top: 10px;
} */

#buy{
  width: 110px;
  position: relative;
  top: 10px;
}

#cashback{
  width: 120px;
  position: relative;
  top: 10px;
}

@media only screen and (max-width : 768px){
  .steps-wrapper{
    display: none;
    visibility: hidden;
  }

  .steps-wrapper-mobile{
    visibility: visible;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 50px 10px 50px;
    text-align: center;
    /* background-color: aliceblue; */
  }

  .steps-info{
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 60px;
    justify-content: space-around;
    align-items: center;
  }

  .steps-info div div:nth-child(1) p{
    font-size: 22px;
    font-weight: 500;
    color: #fb3310;
  }

  .steps-info div div:nth-child(2) p{
    font-size: 18px;
    font-weight: 500;
    color: gray;
  }

  .steps-info div img{
    width: 200px;
    position: relative;
    top: 10px;
  }

  #buy_small{
    width: 130px;
  }

  #cashback_small{
    width: 140px;
  }

  
}