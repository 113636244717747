.otp-wrapper {
  padding: 2rem;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.otp-container {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  height: 90vh;
  justify-content: center;
  max-width: 380px;
  width: 100%;
  padding: 2rem 1.5rem;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #f9f9f9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.otp-box-up{
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: relative;
  top: 0.5rem;
}

.otp-header{
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

.otp-header div{
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
}

.otp-header div p:nth-child(1){
  font-weight: 600;
}

.otp-header div p:nth-child(2){
  color: gray;
  font-size: 13px;
}

.otp-box-up label{
  font-size: 13px;
  position: relative;
  bottom: 4px;
}



.logo-box img{
  width: 100px;
  position: relative;
  top: 0.5rem;
  left: -4.5px;
}

#otp-input{
  padding: 14px 8px;
  width: 100%;
  border-radius: 8px;
  /* border-style: none; */
  border: 0.5px solid #ccc;
  letter-spacing: 0.4px;
  /* box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1); */
}

#otp-input:focus{
  outline-style: none;
}

.buttonBox button:disabled{
  padding: 12px 8px;
  width: 100%;
  color: #fff;
  background-color: rgb(201, 195, 195);
  border-style: none;
  font-size: 15px;
  border-radius: 8px;
  font-weight: 600;
  cursor: none;
}

.buttonBox button{
  padding: 12px 8px;
  width: 100%;
  color: #fff;
  background-color: #fb4828;
  border-style: none;
  font-size: 15px;
  border-radius: 8px;
  font-weight: 600;
  cursor: pointer;
}

.resend-box{
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.resend-box p {
  color: gray;
  font-size: 13px;
}

.resend-box div{
  display: flex;
  align-items: center;
  gap: 1px;
}

.resend-box div:nth-child(2) p {
  color: #fb4828;
}

.resend-box div img{
  width: 20px;
}




.track-box{
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  /* text-align: center; */
  position: relative;
  top: 0.8rem;
}

.track-box p {
  font-size: 14px;
}


.tnc-box{
  text-align: center;
  position: relative;
  top: 1rem;
}

.tnc-box p {
  font-size: 14px;
}

.wrongOtp {
  font-size: 12px;
  color: red;
}

#info_msg{
  font-weight: 500;
  font-size: 13px;
}