.about-us-wrapper {
  margin-top: 64px;
}

#section-one {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5.5rem 0rem 2.5rem 1rem;
  background: linear-gradient(to bottom, aliceblue 85%, #fff 15%);
}

.about-banner-heading {
  width: 45%;
}

.about-banner-heading h1 {
  line-height: 4.5rem;
  font-size: 3.9rem;
  color: black;
  position: relative;
  bottom: 70px;
  left: 10px;
}

.blue-text {
  color: #fb4828;
}



.image-container {
  width: 53%;
}

.image-container img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
  position: relative;
  bottom: 20px;
  right: 20px;
}

#mobile_banner{
  display: none;
  visibility: hidden;
}

#section-two {
  display: flex;
  flex-direction: column;
  gap: 15px;
  text-align: center;
  padding: 2rem 3rem;
}

.section-two-container {
  padding: 2rem 7rem;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.section-two-container h4 {
  font-size: 2.1rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.section-two-container p {
  font-size: 1.2rem;
  line-height: 1.8;
  color: #555;
}

.section-three-heading h4 {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

/* #section-three {
  display: flex;
  flex-direction: column;
  gap: 15px;
  text-align: center;
  padding: 2rem 6rem;
}



.section-three-details {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3rem;
}

.box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  width: 34%;
}


.box h4 {
  font-size: 1.2rem;
  position: relative;
  top: 10px;
}


.box p {
  line-height: 1.8;
  color: #555;
} */

#section-three {
  display: flex;
  flex-direction: column;
  gap: 3.5rem;
  text-align: center;
  padding: 2.5rem 6rem;
  background: linear-gradient(to bottom, #fef8df 100%, #fff 0%);
}

.box-1, .box-3{
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.box-2{
  display: flex;
  justify-content: space-around;
  flex-direction: row-reverse;
  align-items: center;
}

.box-image{
  width: 40%;
}

.box-image img{
  max-width: 40%;
}

.box-detail{
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 50%;
  padding: 0 1rem;
}

.box-detail h4{
  font-size: 2rem;
  position: relative;
}

.box-detail p{
  line-height: 1.8;
  color: #555;
}

.section-four{
  display: flex;
  flex-direction: column;
  gap: 10px;
  text-align: center;
  padding: 2.2rem 2rem;
}

.section-four-container{
  padding: 2rem 7rem;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.section-four-container h4{
  font-size: 2.1rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.section-four-container p{
  font-size: 1.2rem;
  line-height: 1.8;
  color: #555;
}

#section-five{
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 2.5rem 4rem 2.5rem 4rem;
  /* background: linear-gradient(to bottom, #fedfd9 50%, #f8dad5 30%,#fce4e0 20%); */
  background: linear-gradient(to bottom, aliceblue 100%, #fff 0%);
  position: relative;
  min-height: 580px;
}

.section-five-left{
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 30px;
  width: 50%;
}


.section-five-left p {
  font-size: 4.5rem;
  font-weight: bold;
  color: #fff;
}

#scanner{
  width: 100px;
}

.section-five-right{
  width: 50%;
  display: flex;
  align-items: center;
}

.section-five-right img{
  position: relative;
  right: 10px;
  max-width: 90%;
}

.qr-button{
  display: flex;
  align-items: center;
  width: 260px;
  gap: 1rem;
  justify-content: space-around;
  text-decoration: none;
  text-align: center;
  padding: 16px 10px;
  border-radius: 20px;
  background-color: #fb4828;
}

.qr-button img{
  width: 100px;
}

.qr-button p{
  font-size: 1.3rem;
  color: #fff;
}

.qr-mobile{
  display: none;
  visibility: hidden;
}

#shop{
  color: black;
}

@media only screen and (max-width : 768px){
  #section-one {
    flex-direction: column;
    justify-content: center;
    gap: 4.5rem;
    text-align: center;
    padding: 3.5rem 0.5rem 2.5rem;
    background: linear-gradient(to bottom, #fef8df 85%, #fff 20%);
  }

  .about-banner-heading {
    width: 100%;
  }
  
  .about-banner-heading h1 {
    line-height: 3rem;
    font-size: 2.5rem;
    color: #255172;
    position: relative;
    bottom: 0px;
    left: 0px;
  }
  
  .blue-text {
    color: #fb4828;
  }
  
  .image-container {
    width: 100%;
  }
  
  #mobile_banner{
    display: block;
    visibility: visible;
    margin: auto;
  }

  #dektop_banner{
    display: none;
    visibility: hidden;
  }

  .image-container img {
    display: inline-block;
    vertical-align: middle;
    max-width: 90%;
    height: auto;
    position: relative;
    right: 0px;
  }

  #section-two {
    gap: 10px;
    text-align: center;
    padding: 2rem 0.7rem;
  }
  
  .section-two-container {
    padding: 2rem 0.5rem;
  }
  
  .section-two-container h4 {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  
  .section-two-container p {
    font-size: 1rem;
    line-height: 1.6;
    color: #555;
    padding: 0 10px;
  }

  #section-three {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    text-align: center;
    padding: 3rem 0.7rem;
  }

  .box-1, .box-2, .box-3{
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .box-image{
    width: 100%;
  }

  .box-detail{
    width: 100%;
    gap: 0.5rem;
  }

  .box-detail h4{
    font-size: 1.5rem;
  }
  
  .section-three-heading h4 {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  
  .section-three-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3rem;
  }
  
  .box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 90%;
  }
  
  .box h4 {
    font-size: 1.2rem;
  }
  
  
  .box p {
    line-height: 1.8;
    color: #555;
  }

  .section-four{
    display: flex;
    flex-direction: column;
    gap: 10px;
    text-align: center;
    padding: 2rem 0.7rem;
  }
  
  .section-four-container{
    padding: 2rem 0.5rem;
  }
  
  .section-four-container h4{
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  
  .section-four-container p{
    font-size: 1rem;
    line-height: 1.6;
    color: #555;
  }

  #section-five{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 3rem 0.7rem;
    gap: 3.2rem;
  }
  
  .section-five-left{
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 20px;
    width: 100%;
  }
  
  #thumb{
    width: 100px;
  }


  
  
  .section-five-left p {
    font-size: 2.9rem;
    font-weight: bold;
    color: #fff;
  }



  .qr-button{
    display: none;
    visibility: hidden;
    align-self: center;
  }
/*   
  .qr-button img{
    width: 100px;
  }
  
  .qr-button p{
    font-size: 1.3rem;
    color: #fff;
  } */

  .qr-mobile{
    background-color: #fb4828;
    padding: 10px 6px;
    width: 250px;
    align-self: center;
    border-radius: 16px;
    display: block;
    visibility: visible;
    text-decoration: none;
  }

  .qr-mobile p{
    font-size: 1.4rem;
  }



  .section-five-right{
    width: 100%;
    text-align: center;
    display: contents;
  }
  
  .section-five-right img{
    position: relative;
    right: 0;
  }
}


@media only screen and (min-width: 1700px){
  #section-one {
    height: 100vh;
  }


  .image-container {
    width: 55%;
  }
}
@media only screen and (min-width: 1500px) and (max-width: 1600px){

  .section-five-left{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 30px;
    position: relative;
    left: 4rem;
    width: 50%;
  }
  
  .section-five-right{
    width: 50%;
  }
  
  .section-five-right img{
    position: relative;
    right: -5px;
    max-width: 90%;
  }
}