.blog-details-wrapper {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  margin-top: 64px;
}

.blog-details-box {
  display: flex;
  padding: 1.5rem 3rem;
  text-align: justify;
  /* text-justify: inter-word; */
  width: 75%;
  margin: auto;
}

.first-block {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.first-block ul {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.first-block img {
  max-width: 100%;
  border-radius: 10px;
}

#blog-description {
  color: #555;
  max-width: 100%;
  margin-bottom: -20px;
}

#creator {
  font-weight: 600;
}

#blog-info {
  max-width: 100%;
}

#blog-subheading {
  font-weight: 600;
  font-size: 17px;
  color: black;
  margin-bottom: -5px;
}

a {
  color: #4285f4;
  text-decoration: none;
}

#blog-intro {
  font-size: 17px;
  color: black;
}

.hidden {
  display: none;
}

.blog-cards-wrapper {
  display: flex;
  padding: 1.5rem 3rem;
  width: 75%;
  margin: auto;
}

.blog-cards-container {
  display: flex;
  flex-wrap: nowrap;
  gap: 10px;
  overflow-x: auto;
  scroll-behavior: smooth;
  padding: 1.5rem 0.5remrem;
  max-width: 100%;
}

.blog-card {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.blog-card p:nth-child(2) {
  color: gray;
  font-weight: 600;
  font-size: 12px;
}

.blog-card p:nth-child(3) {
  color: black;
  max-width: 100%;
}

.blog-card {
  flex: 0 0 auto; /* Prevent shrinking and keep cards at their natural size */
  width: 300px;
}

.blog-card img {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.blog-cards-container::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.blog-cards-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.blog-social {
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 2rem;
  bottom: 1rem;
  gap: 0.5rem;
}
.blog-social a {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: rgb(113, 114, 114);
  text-align: center;
  vertical-align: middle;
  display: flex;
  justify-content: center;
  align-items: center;
}

.blog-social a img {
  width: 30px;
  display: block;
  margin: auto;
}

#blog-para-text-heading {
  font-weight: 600;
  color: gray;
  margin-bottom: 0.3rem;
}

#blog-para-text {
  font-weight: 400;
}

#blogDate {
  margin-bottom: 0;
  font-size: 13px;
}

#buy_now {
  background-color: #4285f4;
  color: #fff;
  padding: 6px 10px;
  border-radius: 8px;
}

@media only screen and (max-width: 768px) {
  .blog-details-wrapper {
    padding: 1rem 1rem;
  }

  .blog-details-box {
    padding: 1.5rem 0.05rem;
    width: 100%;
  }

  .first-block {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
  }

  .first-block img {
    max-width: 95%;
  }

  .first-block h1 {
    width: 95%;
    text-align: left;
    font-size: 26px;
  }

  #blog-description {
    max-width: 95%;
  }

  #blog-info {
    max-width: 95%;
  }

  .blog-cards-wrapper {
    padding: 1.5rem 0rem;
    width: 100%;
  }

  .blog-cards-container {
    display: flex;
    flex-wrap: nowrap;
    gap: 10px;
    overflow-x: auto;
    scroll-behavior: smooth;
    padding: 1.5rem 0rem;
    max-width: 100%;
  }

  .blog-details-box {
    text-align: left;
  }

  .blog-social {
    right: 1.2rem;
  }
}
