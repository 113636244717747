.navbar-wrapper {
  width: 100%;
  height: 64px;
  display: flex;
  justify-content: space-between;
  padding: 0 1.5rem;
  /* gap: 200px; */
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 99;
  background-color: #fff;
  /* width: 100vw; */
}

.desktop-blog-link{
  display: block;
  visibility: visible;
}

#hutti-logo {
  width: 100px;
}

.nav-tab{
  display: flex;
  gap: 15px;
}

.nav-link{
  text-decoration: none;
  font-weight: 600;
  color: #555;
}

.nav-link.active{
  color: #fb4828;
}



.mobile-blog-link{
  display: none;
  visibility: hidden;
}

@media only screen and (max-width: 768px){
  .navbar-wrapper {
    padding: 0 0.8rem;
  }

  .mobile-blog-link{
    display: block;
    visibility: visible;
  }

  .desktop-blog-link{
    display: none;
    visibility: hidden;
  }
}





