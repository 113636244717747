.feature-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 10px;
  gap: 30px;
  background: rgb(36,33,0);
  background: linear-gradient(135deg, rgb(250 248 229) 0%, rgb(249 245 214 / 79%) 35%, rgb(228 247 244 / 54%) 100%);

}

.feature-wrapper-mobile {
  display: none;
  visibility: hidden;
}

.box-one {
  display: flex;
  justify-content: space-around;
  text-align: center;
  width: 100%;
}

.box-one div:nth-child(1) {
  width: 50%;
  text-align: center;
}

.box-one img {
  width: 300px;
}

.box-one div:nth-child(2) {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 10px;
}

.box-one div:nth-child(2) p:nth-child(1) {
  font-size: 28px;
  font-weight: 600;
}

.box-one div:nth-child(2) p:nth-child(2) {
  font-size: 18px;
}

.box-two {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-around;
  text-align: center;
  width: 100%;
}

.box-two div:nth-child(1) {
  width: 50%;
  text-align: center;
}

.box-two img {
  width: 400px;
}

.box-two div:nth-child(2) {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 10px;
}

.box-two div:nth-child(2) p:nth-child(1) {
  font-size: 28px;
  font-weight: 600;
}

.box-two div:nth-child(2) p:nth-child(2) {
  font-size: 18px;
}

@media only screen and (max-width: 768px) {
  .feature-wrapper {
    display: none;
    visibility: hidden;
  }

  .feature-wrapper-mobile {
    visibility: visible;
    display: flex;
    flex-direction: column;
    gap: 50px;
    padding: 40px 40px;
    background-color: aliceblue;
  }

  .box {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  .box div:nth-child(1) {
    width: 100%;
    text-align: center;
  }

  .box img {
    width: 150px;
  }

  .box div:nth-child(2) {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 10px;
  }

  .two img {
    left: 0;
    width: 220px;
  }

  .box div:nth-child(2) p:nth-child(1) {
    font-size: 24px;
    font-weight: 600;
  }
  
  .box div:nth-child(2) p:nth-child(2) {
    font-size: 16px;
    color: gray;
  }
}
