.antispampolicy_wrapper{
  display: flex;
  flex-direction: column;
  padding: 15px 45px;
  gap: 10px;
}

@media only screen and (max-width: 768px) {
  .antispampolicy_wrapper{
    padding: 15px 20px;
  }



}