@font-face {
  font-family: "Montserrat";
  src: url("../Fonts/Montserrat-Medium.ttf") format("truetype");
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}

p{
  margin-bottom: 0;
}

