*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


.privacy_wrapper{
  display: flex;
  flex-direction: column;
  padding: 15px 45px;
  text-align: left;
}

.list_heading{
  font-weight: 600;
  font-size: 17px;
}

@media only screen and (max-width: 768px) {
  .privacy_wrapper{
    display: flex;
    flex-direction: column;
    padding: 15px 20px;
  }

  .list_heading{
    font-weight: 600;
    font-size: 14px;
  }

  .roman_list{
    padding-left: 0;
  }
  
}
