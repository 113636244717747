.banner-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 30px;
  padding-bottom: 0;
  background-color: aliceblue;
  height: 100vh;
  position: relative;
  margin-top: 30px;
}

.qrBox{
  display: flex;
  align-items: center;
  width: 240px;
  justify-content: space-around;
  text-decoration: none;
  text-align: center;
  padding: 10px 8px;
  position: fixed;
  bottom: 20px;
  border-radius: 20px;
  background-color: #fb4828;
  z-index: 99;
}

.hidden {
  display: none;
}


.qrBox img{
  width: 85px;
  height: 85px;
}

.qrBox p{
  font-weight: 700;
  color: #fff;
  font-size: 20px;
}

.banner-mobile {
  display: none;
  visibility: hidden;
}

.banner-text {
  width:51%;
  padding-left: 45px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.banner-text button {
  padding: 10px 18px;
  border-style: none;
  background: rgb(131, 58, 180);
  background: linear-gradient(
    90deg,
    #fb3310 40%,
    #f83253 100%
  );
  border-radius: 8px;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  position: relative;
  top: 5px;
}

#heading {
  font-size: 2.4rem;
  font-weight: 600;
  color: #fb3310;
}

#sub-heading {
  font-size: 1.1rem;
  color: black;
}

.banner-img {
  width: 0%;
  text-align: center;
}

.banner-img img {
  width: 49%;
  position: absolute;
  bottom: 0;
  right: 40px;


}

#download-button {
  width: 200px;
  position: relative;
  right: 12.5px;
}



@media only screen and (max-width: 768px) {
  .banner-wrapper {
    display: none;
    visibility: hidden;
  }

  .banner-mobile {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 30px;
    visibility: visible;
    margin-top: 64px;
    text-align: center;
    padding: 30px 20px 0;
    /* background-color: rgb(255, 105, 105); */
    background-color: aliceblue;
    position: relative; /* Added to serve as a reference for the absolute positioning */
  }

  /* .banner-heading p {
    font-size: 32px;
    font-weight: 600;
    color: #fb3310;
  } */

  .banner-heading p {
    font-size: 27px;
    font-weight: 600;
    /* Add a background gradient */
    /* background: linear-gradient(to bottom, #ff912e, #fa1e6a, #dc2100); */
    /* Clip the background to the text */
    /* -webkit-background-clip: text; */
    /* background-clip: text; */
    /* Make the text color transparent so that the background is visible through it */
    /* color: transparent; */
    color: #fb3310;
  }

  #sub-heading {
    font-size: 16px;
    color: black;
    font-weight: 500;
    position: relative;
    top: 5px;
  }

  .banner-heading span {
    font-size: 15px;
    font-weight: 500;
  }

  .banner-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  .banner-button button {
    padding: 14px 60px;
    /* width: 150px; */
    border-radius: 22px;
    border-style: none;
    font-size: 18px;
    font-weight: 600;
    color: #fff;
    background-color: #fb3310;
  }

  .banner-button p {
    font-size: 15px;
    font-weight: 500;
    text-decoration: underline;
  }

  .banner-mobile-image {
    text-align: center;
  }

  .banner-mobile-image img {
    position: relative;
    bottom: -5px;
    width: 250px;
  }
}

@media only screen and (min-width: 1500px) and (max-width: 1600px){
  .banner-wrapper {
    height: 100vh;
    position: relative;
    margin-top: 0px;
  }

  .banner-img img {
    width: 50%;
  }

  .qrBox{
    display: flex;
    align-items: center;
    width: 260px;
    justify-content: space-around;
    text-decoration: none;
    text-align: center;
    padding: 20px 5px;
    position: fixed;
    bottom: 120px;
    border-radius: 20px;
    background-color: #fb4828;
  }
  
  .hidden {
    display: none;
  }
  
  
  .qrBox img{
    width: 90px;
    height: 90px;
  }
  
  .qrBox p{
    font-weight: 700;
    color: #fff;
    font-size: 22px;
  }
} 

@media only screen and (min-width: 1600px){
  .banner-wrapper {
    height: 100vh;
    position: relative;
    margin-top: 0px;
  }

  .banner-img img {
    width: 52%;
  }

  .qrBox{
    display: flex;
    align-items: center;
    width: 300px;
    justify-content: space-around;
    text-decoration: none;
    text-align: center;
    padding: 15px 10px;
    position: fixed;
    bottom: 210px;
    border-radius: 20px;
    background-color: #fb4828;
  }
  
  .hidden {
    display: none;
  }
  
  
  .qrBox img{
    width: 90px;
    height: 90px;
  }
  
  .qrBox p{
    font-weight: 700;
    color: #fff;
    font-size: 22px;
  }
} 